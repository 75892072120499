@import '../../styles/index';

.base {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  width: 90%;
  z-index: 2;

  @include media-from(xs) {
    display: none;
  }
}

.filterToggle span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterIcon {
  margin-right: 0.5rem;
}
