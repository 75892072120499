@import '../../styles/index';

.cord-power {
  --translate: 0.5rem, 0.25rem;
  animation: cord 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) infinite;

  @include media-from(xs) {
    --translate: 0.5rem, -1.75rem;
  }
}

.balloon-power {
  --translate: 0.5rem, 2rem;
  animation: balloon 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) infinite;

  @include media-from(xs) {
    --translate: 0.5rem, -3.5rem;
  }
}

.balloon-attachment-power {
  --translate: 0.5rem, 2rem;
  animation: balloon-attachment 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) infinite;

  @include media-from(xs) {
    --translate: 0.5rem, -3.75rem;
  }
}

.cord-windplants {
  --translate: -0.5rem, -2rem;
  animation: cord 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) 1s infinite;
}

.balloon-windplants {
  --translate: -0.5rem, -2rem;
  animation: balloon 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) 1s infinite;

  @include media-from(xs) {
    --translate: -0.5rem, -2rem;
  }
}

.balloon-attachment-windplants {
  --translate: -0.5rem, -2.25rem;
  animation: balloon-attachment 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) 1s
    infinite;
}

.cord-windparks {
  --translate: 0.5rem, -4rem;
  animation: cord 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) infinite 2s;
}

.balloon-windparks {
  --translate: 0.5rem, -0.25rem;
  animation: balloon 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) infinite 2s;

  @include media-from(xs) {
    --translate: 0.5rem, -2.5rem;
  }
}

.balloon-attachment-windparks {
  --translate: 0.5rem, -2.7rem;
  animation: balloon-attachment 7s cubic-bezier(0.36, 0.07, 0.19, 0.67) infinite
    2s;
}

@keyframes cord {
  50% {
    transform: translate(var(--translate));
  }
}

@keyframes balloon {
  50% {
    transform: translate(var(--translate));
  }
}

@keyframes balloon-attachment {
  50% {
    transform: translate(var(--translate));
  }
}
